<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-form ref="form" @submit.prevent="save">
        <v-card-title>
          {{ accountData.id ? '修改' : '新增' }}帳號
        </v-card-title>
        <v-card-text>
          <template v-if="accountData.id">
            <div class="text-caption">帳號</div>
            <div class="black--text text-body-1 mb-4">
              {{ params.userid || '-' }}
            </div>
          </template>
          <v-text-field
            v-else
            v-model="params.userid"
            label="帳號"
            type="text"
            :rules="[(v) => !!v || '必填']"
          ></v-text-field>
          <v-text-field
            v-if="!accountData.id"
            v-model="params.password"
            label="密碼"
            type="text"
            :rules="[(v) => !!v || '必填']"
          ></v-text-field>
          <v-text-field
            v-model="params.name"
            label="名稱"
            type="text"
            :rules="[(v) => !!v || '必填']"
          ></v-text-field>
          <template v-if="accountData.id">
            <div class="text-caption">OpenID Email</div>
            <div class="black--text text-body-1 mb-4">
              {{ accountData.openIdEmail || '-' }}
            </div>
          </template>
          <template v-if="accountData.id">
            <div class="text-caption">OpenID 角色</div>
            <div class="black--text text-body-1 mb-4">
              {{
                (accountData.openIdRoles.length &&
                  accountData.openIdRoles[0].remarks) ||
                '-'
              }}
            </div>
          </template>
          <template v-if="accountData.id">
            <div class="text-caption">OpenID 職稱</div>
            <div class="black--text text-body-1 mb-4">
              {{ accountData.openIdTitles || '-' }}
            </div>
          </template>
          <v-text-field
            v-model="params.email"
            label="email"
            type="text"
            :rules="emailRules"
            clearable
          ></v-text-field>
          <v-select
            :items="schoolRolesOption"
            label="角色"
            v-model="params.role"
            item-text="remarks"
            item-value="id"
            return-object
            clearable
          ></v-select>
          <v-select
            v-if="needClassId"
            :items="classOption"
            label="班級"
            v-model="selectedClass"
            item-text="name"
            item-value="classId"
            return-object
            :rules="[(v) => !!v || '必填']"
          ></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="$emit('input', false)"> 取消 </v-btn>
          <v-btn color="primary" text type="submit" :loading="loadingSave">
            {{ accountData.id ? '修改' : '新增' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Account from '@/api/Account'
import BranchSchool from '@/api/ems/BranchSchool'

export default {
  name: 'SchoolAccountAccountForm',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountData: {
      type: Object,
      default: () => {}
    },
    schoolData: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      params: {},
      selectedClass: {},
      classOption: [],
      loadingSave: false,
      show: false,
      emailRules: [
        (value) => {
          if (value) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'e-mail 格式有誤'
          }
          return true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      tags: 'page/tags',
      rolesOption: 'role/schoolRolesOption'
    }),
    schoolRolesOption() {
      if (!this.rolesOption) {
        return []
      }
      return this.rolesOption.filter((role) =>
        [
          'SCH_USER',
          'CLASS_USER',
          'SCH_ADMIN',
          'RL_CLIENT',
          'SCH_AFFAIRS'
        ].includes(role.name)
      )
    },
    createParams() {
      return {
        userid: this.params.userid,
        email: this.params.email ? this.params.email : null,
        password: this.params.password,
        name: this.params.name,
        schoolId: this.schoolData.id,
        classId: this.selectedClass?.classId,
        cityId: this.schoolData.cityId,
        districtId: this.schoolData.districtId,
        roles: this.params.role ? [this.params.role] : null
      }
    },
    updateParams() {
      return {
        name: this.params.name,
        email: this.params.email ? this.params.email : null,
        roles: this.params.role ? [this.params.role] : null,
        classId: this.selectedClass?.classId,
        schoolId: this.accountData.schoolId,
        cityId: this.accountData.cityId,
        districtId: this.accountData.districtId
      }
    },
    needClassId() {
      return ['CLASS_USER'].includes(this.params.role?.name)
    }
  },
  watch: {
    value(val) {
      this.show = val
    },
    show(val) {
      this.$emit('input', val)
      if (val) {
        if (this.accountData.id) {
          Object.assign(this.params, this.accountData)
          this.params = {
            userid: this.accountData.userid,
            name: this.accountData.name,
            role: this.accountData.roles?.length && this.accountData.roles[0],
            email: this.accountData.email
          }
          this.selectedClass.classId = this.accountData.classId
        }
      } else this.resetParams()
    },
    schoolData: {
      handler() {
        this.getClasses()
      },
      deep: true
    }
  },
  mounted() {
    if (!this.rolesOption || this.rolesOption.length === 0) {
      this.getRoles()
    }
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getRoles: 'role/getSchoolRolesOption'
    }),
    async getClasses() {
      try {
        const { data } = await BranchSchool.getClassName(this.schoolData.id)
        this.classOption = data.classes
      } catch (error) {
        console.error(error)
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        const exeFunction = this.accountData.id
          ? Account.update(this.accountData.id, this.updateParams)
          : Account.create(this.createParams)
        try {
          this.loadingSave = true
          await exeFunction
          this.resetParams()
          this.$emit('input', false)
          this.$emit('save')
        } catch (error) {
          console.error(error)
          if (error.response?.data?.message) alert(error.response.data.message)
          else alert(`${this.accountData.id ? '修改' : '新增'}失敗`)
        } finally {
          this.loadingSave = false
        }
      }
    },
    resetParams() {
      this.params = {}
    }
  }
}
</script>

<style></style>
