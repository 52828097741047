<template>
  <div>
    <v-sheet
      class="accent--text font-weight-bold mb-2 pa-4"
      color="ems-light-periwinkle"
      width="100%"
    >
      {{ title }}
    </v-sheet>
    <v-card class="pa-4">
      <v-data-table :headers="headers" :items="schoolAccounts">
        <template v-slot:[`item.roles`]="{ item }">
          <div>
            {{ item.roles ? item.roles.map((r) => r.remarks).join(', ') : '' }}
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon class="mr-5" @click="updateAccount(item)">
            mdi-pencil
          </v-icon>
          <v-icon @click="removeAccount(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Account from '@/api/Account'
import Swal from 'sweetalert2'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    roleName: {
      type: String,
      default: ''
    },
    schoolAccounts: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      headers: [
        { text: '名稱', value: 'name' },
        { text: '帳號', value: 'userid' },
        {
          text: 'OpenID Email',
          value: 'openIdEmail'
        },
        {
          text: 'OpenID 角色',
          value: 'openIdRoles',
          sort: (a, b) => {
            return a
              .map(({ remarks }) => remarks)
              .join()
              .localeCompare(b.map(({ remarks }) => remarks).join())
          }
        },
        {
          text: 'OpenID 職稱',
          value: 'openIdTitles'
        },
        { text: 'Email', value: 'email' },
        { text: '角色', value: 'roles' },
        { text: '建立時間', value: 'createdDate' },
        { text: '', value: 'action', sortable: false, width: '100' }
      ],
      showForm: false,
      accountData: {},
      schoolId: this.$route.params.schoolId
    }
  },
  methods: {
    async updateAccount(account) {
      this.$emit('updateAccount', account)
    },
    async removeAccount(account) {
      Swal.fire({
        title: `確定要刪除帳號 ${account.userid} ?`,
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '確定',
        confirmButtonColor: '#3085d6'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await Account.delete(account.id)
            this.$emit('deleteAccount')
          } catch (error) {
            console.error(error)
          }
        }
      })
    }
  }
}
</script>
