<template>
  <div>
    <AccountForm
      v-model="showForm"
      :accountData="accountData"
      :schoolData="school"
      @save="getAccounts"
    />
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6 font-weight-bold">
          {{ school.name }}
        </div>
        <v-btn
          small
          icon
          @click="
            showForm = true
            accountData = {}
          "
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <div class="pa-4" v-for="role in roleList" :key="role.name">
        <RoleAccountList
          :title="role.title"
          :roleName="role.name"
          :schoolAccounts="filteredSchoolAccounts(role.name)"
          :schoolId="schoolId"
          @updateAccount="updateAccount"
          @deleteAccount="getAccounts"
        />
      </div>
      <div class="pa-4">
        <RoleAccountList
          title="其餘帳號"
          :schoolAccounts="noRoleAccounts"
          :schoolId="schoolId"
          @updateAccount="updateAccount"
          @deleteAccount="getAccounts"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import School from '@/api/admin/School'
import Account from '@/api/Account'
import AccountForm from './AccountForm'
import RoleAccountList from './RoleAccountList'

export default {
  name: 'SchoolAccountIndex',
  mixins: [],
  components: {
    AccountForm,
    RoleAccountList
  },
  props: {
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      school: {},
      schoolAccounts: [],
      accountData: {},
      showForm: false,
      roleList: [
        { title: '學校管理員', name: 'SCH_ADMIN' },
        { title: '學校事務組長', name: 'SCH_AFFAIRS' },
        { title: '學校使用者', name: 'SCH_USER' },
        { title: '班級使用者', name: 'CLASS_USER' }
      ]
    }
  },
  computed: {
    noRoleAccounts() {
      return this.schoolAccounts.filter((account) => {
        const realRole =
          (account.roles.length && account.roles[0].name) ||
          (account.openIdRoles.length && account.openIdRoles[0].name) ||
          undefined
        return !this.roleList.find(({ name }) => name === realRole)
      })
    }
  },
  mounted() {
    this.getSchools()
    this.getAccounts()
  },
  destroyed() {},
  methods: {
    async getSchools() {
      try {
        const { data } = await School.list(this.cityId)
        this.allSchools = data
        this.school = data.find((school) => school.id === this.schoolId) || {}
      } catch (error) {
        console.error(error)
      }
    },
    async getAccounts() {
      try {
        const { data } = await Account.list()
        this.schoolAccounts = data.filter(
          (account) => account.schoolId === this.schoolId
        )
      } catch (error) {
        console.error(error)
      }
    },
    updateAccount(account) {
      this.showForm = true
      this.accountData = account
    },
    filteredSchoolAccounts(roleName) {
      return this.schoolAccounts.filter((account) => {
        const realRole =
          (account.roles.length && account.roles[0].name) ||
          (account.openIdRoles.length && account.openIdRoles[0].name) ||
          undefined
        return realRole === roleName
        // account.roles?.find((role) => role.name === this.roleName)
      })
    }
  }
}
</script>

<style></style>
